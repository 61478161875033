<template>
  <v-dialog
    v-model="showForm"
    style="z-index:501"
    max-width="450"
    overlay-opacity="0.5"
  >
    <v-card elevation="10">
      <v-form
        ref="cable_changeForm"
        v-model="valid"
        lazy-validation
      >
        <v-card-title class="text-h5">
          {{ $t('new_connections.edit_cable') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col        
                cols="12"
              >
                <v-expansion-panels>
                  <v-expansion-panel>
                    <v-expansion-panel-header>{{ $t('new_connections.change_cable_current') }}</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div v-for="(detail, index) in installedCableDetails">
                          <p class="text-left">
                            {{ $t('cable_change_description.'+ index) + ': ' + detail }}
                          </p>
                        </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="values.Cable_id"
                  :items="listCables"
                  :label="$t('new_connections.cable')"
                  item-text="reference"
                  item-value="id"
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.r_cable_ohm_km"
                  :rules="VALIDATIONS.r_cable_ohm_km"
                  :label="$t('new_connections.change_cable_r_km')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.x_cable_ohm_km"
                  :rules="VALIDATIONS.x_cable_ohm_km"
                  :label="$t('new_connections.change_cable_x_km')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.r_neutral_ohm_km"
                  :rules="VALIDATIONS.r_neutral_ohm_km"
                  :label="$t('new_connections.change_cable_r_neutral')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.x_neutral_ohm_km"
                  :rules="VALIDATIONS.x_neutral_ohm_km"
                  :label="$t('new_connections.change_cable_x_neutral')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.i_max_amp"
                  :rules="VALIDATIONS.i_max_amp"
                  :label="$t('new_connections.change_cable_maximum_current')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="values.i_max_neutral_amp"
                  :rules="VALIDATIONS.i_max_neutral_amp"
                  :label="$t('new_connections.change_cable_maximum_neutral')"
                  :disabled="values.Cable_id !== -1"
                  type="number"
                  dense
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <p v-if="lineFull">
          {{ $t('new_connections.line_full') }}
        </p>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="#f4c020"
            text
            @click="closeForm"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="#f4c020"
            text
            :disabled="!valid"
            @click="deleteDevice"
          >
            {{ $t('delete') }}
          </v-btn>
          <v-btn
            :disabled="!valid || values.Cable_id === installedCable.base_cable_id"
            data-cy="dt-add-ev"
            color="#f4c020"
            text
            @click="confirmCableChange"
          >
            {{ $t('confirm') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import digitalTwinForm from '@/mixins/digitalTwinForm'
import dtNewElementMixin from '@/mixins/dtNewElementMixin'
import { getInventoryCables } from '@/services/cableChange'
import { mapState } from 'vuex'

export default {
  name: 'DTCableChangeForm',
  mixins: [digitalTwinForm, dtNewElementMixin],
  data () {
    return {
      DEVICE: 'cable_change',
      // If it is custom you can modify values, if you select an existant cable, the inputs are disabled
      values: {
        Line_id: null,
        Cable_id: null,
        r_cable_ohm_km: null, 
        x_cable_ohm_km: null, 
        r_neutral_ohm_km: null, 
        x_neutral_ohm_km: null, 
        i_max_amp: null,
        i_max_neutral_amp: null,
        is_custom: null,
        base_cable_id: null
      },
      newBackend: true,
      customCable: { 
        id: -1,
        reference: 'Custom', 
        r_cable_ohm_km: 0.01, 
        x_cable_ohm_km: 0.01, 
        r_neutral_ohm_km: 0.01, 
        x_neutral_ohm_km: 0.01, 
        i_max_amp: 100,
        i_max_neutral_amp: 100,
        is_custom: true
      },
      availableCables: [],
      installedCable: {
        base_cable_id: null,
        reference: null,
        r_cable_ohm_km: null, 
        x_cable_ohm_km: null,
        r_neutral_ohm_km: null, 
        x_neutral_ohm_km: null, 
        i_max_amp: null ,
        i_max_neutral_amp: null,
        is_custom: null
      }
    }
  },
  computed: {
    ...mapState({
      digitalTwinResults: (state) => state.DTResults,
      CableChange: (state) => state.DTChanges.cable_change,
      contextMenuElement: ({ contextMenuElement }) => contextMenuElement,
    }),
    listCables () {
      return [this.customCable, ...this.availableCables]
    },
    installedCableDetails() {
      return this.listCables.find(cable => cable.id === this.installedCable.base_cable_id) || { reference: 'Custom' }
    },
    isOnCableChangeList () {
      return this.CableChange.find((cable) => cable.Line_id === this.contextMenuElement.info.ID)
    }
  },
  watch: {
    'values.Cable_id'(newId) {
      if (this.values.is_custom === false) {
        const selectedCable = this.listCables.find(cable => cable.id === newId)
        if (selectedCable) {
          this.assignCableValues(selectedCable)
        }
      }
    }
  },
  async mounted() {
    this.installedCable = await this.digitalTwinResults.getInstalledCable(this.contextMenuElement.info.ID)
    this.availableCables = await getInventoryCables()

    // Check before if this line is already in DTChanges
    if (this.isOnCableChangeList) {
      this.assignCableValues(this.isOnCableChangeList)
      // If it is in DTChanges we should also give a value to currentMarker
      const lineMarker = this.markers.find(
        (m) => { return parseInt(m.element.ID) === parseInt(this.isOnCableChangeList.Line_id)}
      )

      this.$store.dispatch('setElement', {
        path: 'currentMarker',
        value: lineMarker
      })

    } else {
      this.assignCableValues(this.installedCable)
    }

  },
  methods: {
    confirmCableChange () {
      this.values.r_cable_ohm_km = parseFloat(this.values.r_cable_ohm_km)
      this.values.x_cable_ohm_km = parseFloat(this.values.x_cable_ohm_km)
      this.values.r_neutral_ohm_km = parseFloat(this.values.r_neutral_ohm_km)
      this.values.x_neutral_ohm_km = parseFloat(this.values.x_neutral_ohm_km)
      this.values.i_max_amp = parseFloat(this.values.i_max_amp)
      this.values.i_max_neutral_amp = parseFloat(this.values.i_max_neutral_amp)

      // Check before if this line is in DTChanges
      if (this.isOnCableChangeList) {
        this.updateChanges({
          append: false,
          customIndex: 0 // Always 0 ?
        })

      } else {
        this.addDevice()
      }
      this.closeForm()
    },
    assignCableValues(cable) {
      const lineId = this.contextMenuElement.info.ID
      this.values.Line_id = lineId
      this.values.Cable_id = cable.id ?? cable.Cable_id ?? cable.base_cable_id
      this.values.r_cable_ohm_km = cable.r_cable_ohm_km
      this.values.x_cable_ohm_km = cable.x_cable_ohm_km
      this.values.r_neutral_ohm_km = cable.r_neutral_ohm_km
      this.values.x_neutral_ohm_km = cable.x_neutral_ohm_km
      this.values.i_max_amp = cable.i_max_amp
      this.values.i_max_neutral_amp = cable.i_max_neutral_amp
      this.values.is_custom = cable.is_custom ?? false
      this.values.base_cable_id = this.installedCable.base_cable_id
    }
  }

}
</script>
<style scoped>
.text-left {
  text-align: left;
}
</style>