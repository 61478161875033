<template>
  <div id="app">
    <div
      data-app
      class="full-height"
    >
      <div v-if="!layoutLess">
        <LYSideBar />
        <LYNavBar />
      </div>
      <VMain class="full-height">
        <RouterView style="padding-left: 0px; padding-right: 0px" />
      </VMain>
    </div>
  </div>
</template>

<script>
import LYNavBar from '@/components/Layout/LYNavBar.vue'
import LYSideBar from './components/Layout/LYSideBar.vue'
import ConfigService from './services/configService'
import Vue from 'vue'

export default {
  name: 'App',

  components: {
    LYNavBar,
    LYSideBar
  },

  data: () => ({
    layoutLess: false,
    exceptions: ['Login', 'PageNotFound']
  }),

  metaInfo: {
    title: 'Plexigrid',
    titleTemplate: 'Plexigrid | %s'
  },
  computed: {
    view () {
      return this.$route.name
    }
  },

  watch: {
    async view () {
      this.setLayoutLess()
    }
  },

  async mounted () {
    this.setLayoutLess()

    const configService = ConfigService.getInstance()
    
    Vue.prototype.$HEADER_HTTP = await configService.getConfig("headerHttp")
    Vue.prototype.$API_HOST = await configService.getConfig("apiHost")
    Vue.prototype.$API_PORT = await configService.getConfig("apiPort")

    console.log('Following values set in Vue.prototype')
    console.log(Vue.prototype.$HEADER_HTTP)
    console.log(Vue.prototype.$API_HOST)
    console.log(Vue.prototype.$API_PORT)
  },

  methods: {
    setLayoutLess () {
      this.layoutLess = this.exceptions.includes(this.$route.name)
    }
  }
}

</script>

<style>

body {
  padding-right: 0px !important;
  background-color: #272727;
  height: inherit;
}
html {
  overflow-y: auto;
  height: 100%;
}
#app {
  font-family: 'Faktum Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}
.v-application--wrap {
  min-height:0vh !important;
}
.full-height{
  height: 100%;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: inset 0 0 5px #333;
  box-shadow: inset 0 0 5px #111;
  border:1px solid #333;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border:1px solid #333;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #777;
}

.p-24 {
  padding: 24px;
}

.p-12 {
  padding: 12px;
}

.mt-24 {
  margin-top: 24px;
}

.my-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.color-primary {
  color: #f4c020
}
</style>
