import { render, staticRenderFns } from "./DTCableChangeForm.vue?vue&type=template&id=0573b585&scoped=true"
import script from "./DTCableChangeForm.vue?vue&type=script&lang=js"
export * from "./DTCableChangeForm.vue?vue&type=script&lang=js"
import style0 from "./DTCableChangeForm.vue?vue&type=style&index=0&id=0573b585&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0573b585",
  null
  
)

export default component.exports