<template>
  <div class="login">
    <form
      action
      class="form"
      @submit.prevent="login"
    >
      <v-img
        src="@/assets/images/logoPlexigridYellow.png"
        max-height="100"
        max-width="100"
        class="centerPlexi"
      />
      <template v-if="!source">
        <label
          class="form-label"
          for="#email"
        >{{ $t('email') }}</label>
        <input
          id="email"
          v-model="email"
          class="form-input"
          type="text"
          required
          :placeholder="$t('email')"
        >
        <label
          class="form-label"
          for="#password"
        >{{ $t('login.password') }}</label>
        <input
          id="password"
          v-model="password"
          class="form-input"
          type="password"
          :placeholder="$t('login.password')"
        >
        <p
          v-if="error"
          class="error"
        >
          {{ error_message }}
        </p>
        <v-btn
          class="form-submit"
          type="submit"
        >
          {{ $t('login.login') }}
        </v-btn>
      </template>

      <template v-else>
        <ASForm
          v-if="typeof(source) === 'object'"
          :source="source"
        />
        <p
          v-else
          class="error"
        >
          {{ $t('grid_set.error') }}
        </p>
      </template>
    </form>

    <CMLanguagePicker />

    <CMReleaseVersion />
  </div>
</template>

<script>

import { login } from '@/services/auth'
import { getAreaMap } from '@/services/areaSelector'
import ASForm from '@/components/AreaSelector/ASForm.vue'
import CMLanguagePicker from '@/components/Common/CMLanguagePicker.vue'
import CMReleaseVersion from '@/components/Common/CMReleaseVersion.vue'
import { parseJwt } from '@/assets/queries'

export default {
  name: 'LoginView',

  components: { ASForm, CMLanguagePicker, CMReleaseVersion },

  metaInfo: {
    title: 'Login'
  },

  data: () => ({
    email: '',
    password: '',
    error: false,
    token: '',
    source: null,
    error_message: ''
  }),

  mounted () {
    this.resetTIA()
    this.$store.dispatch('setElement', { path: 'logged', value: false })
    if (this.$sessionStorage.theme) {
      this.$store.dispatch('setElement', {
        path: 'theme',
        value: this.$sessionStorage.theme
      })
    }
    this.$sessionStorage.logged = false
    this.$sessionStorage.zoneId = null
    this.$sessionStorage.gridElement = null
    this.$sessionStorage.timeZone = null

    sessionStorage.clear()
  },

  methods: {
    async login () {
      try {
        const result = await login(this.email, this.password)
        sessionStorage.access = result.access
        sessionStorage.refresh = result.refresh

        sessionStorage.user_id = parseJwt(result.access).user_id
        sessionStorage.setItem('logged', true)
        this.$sessionStorage.logged = true
        this.source = await getAreaMap()

        sessionStorage.timeZone = this.source.TIMEZONE
        this.$sessionStorage.timeZone = this.source.TIMEZONE
        sessionStorage.projectCoordSystem = this.source.PROJ4
        this.$sessionStorage.projectCoordSystem = this.source.PROJ4

        this.$sessionStorage.theme = 'dark'
      } catch (error) {
        console.log(error)
        this.error = true
        this.error_message = error.text
      }
    },
    resetTIA(){
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.nameStationSelected', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMap.stationSelected', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedId', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.TIAMonitoring.supplyPointSelectedName', value: null })
      this.$store.dispatch('setElement', { path: 'TIA.tabTIA', value: 0 })
      this.$store.dispatch('setElement', { path: 'TIA.timeMachine', value: null })
    }
  }
}
</script>

<style lang="scss" scoped>
.mb-5 {
  margin-bottom: 5em;
}

.login {
  padding: 2rem;
}
.title {
  text-align: center;
  font-size: 24px;
  color: #f4c020;
}
.form {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 20%;
  min-width: 350px;
  max-width: 100%;
  background: #1e1e1e;
  border-radius: 5px;
  padding: 40px;
  box-shadow: 0 4px 10px 4px rgba(0, 0, 0, 0.3);
}
.form-label {
  align-self: flex-start;
  margin-top: 2rem;
  color: white;
  margin-bottom: 0.5rem;
  &:first-of-type {
    margin-top: 0rem;
  }
}
.form-input {
  padding: 10px 15px;
  background: none;
  background-image: none;
  border: 1px solid white;
  color: white;
  &:focus {
    outline: 0;
    border-color: #f4c020;
  }
}
.form-submit {
  background: #272727 !important;
  border: none;
  color: #f4c020 !important;
  margin-top: 3rem;
  padding: 1rem 0;
  cursor: pointer;
  text-align: center;
  transition: background 0.2s;
  border-radius: 3px;
  &:hover {
    background: #f4c020 !important;
    color: #000 !important;
  }
}
.error {
  margin: 1rem 0 0;
  color: #ff4a96;
}
.msg {
  margin-top: 3rem;
  text-align: center;
}
.centerPlexi{
  margin-left: 34%;
  margin-bottom: 15px;
}
</style>
