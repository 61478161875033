<template>
  <VAppBar
    color="#000000"
    dark
    clipped-left
    app
  >
    <VAppBarNavIcon v-if="logged">
      <RouterLink :to="{ name: 'DashboardView' }">
        <VTooltip right>
          <template #activator="{ on }">
            <v-img
              class="mr-3"
              src="@/assets/images/logoPlexigridYellow.png"
              max-height="40"
              max-width="40"
              v-on="on"
            />
          </template>
          <span>{{ $t('nav_bar.title') }}</span>
        </VTooltip>
      </RouterLink>
    </VAppBarNavIcon>

    <VSpacer />
    <CMReleaseVersion />
    <VMenu
      v-if="logged"
      v-model="showMenuNetwork"
      absolute
      offset-y
      style="max-width: 90px"
      justify="bottom"
    >
      <template #activator="{ on }">
        <div
          class="network-button"
          v-on="on"
        >
          {{ $t('grid.network') }}
          <VIcon style="color:#F4C020">
            {{ chevronDown }}
          </VIcon>
        </div>
      </template>
      <VList>
        <VListItem
          style="color:#F4C020;font-size:13px;"
        >
          {{ $t('nav_bar.configure') }}
        </VListItem>
        <VListItem>
          <div
            id="areaButton"
            class="v-list-item--link map-area-button"
            @click="setArea"
          >
            {{ $t('nav_bar.set_grid') }}
          </div>
        </VListItem>
        <VListItem
          v-for="(item, index) in networksItems"
          :key="index"
          :to="item.link"
        >
          <VListItemTitle>{{ item.title }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>

    <VMenu
      v-if="logged"
      v-model="showMenu"
      absolute
      offset-y
      style="max-width: 90px"
      justify="bottom"
    >
      <template #activator="{ on }">
        <v-img
          class="mr-3 user-button"
          src="@/assets/images/logoUserYellow.png"
          max-height="40"
          max-width="40"
          style="cursor:pointer"
          v-on="on"
        />
      </template>
      <VList>
        <VListItem
          style="color:#F4C020; font-size:13px;text-align: left!important;"
        >
          {{ userInfo?.username }} <br> {{ userInfo?.email }} <br>
          {{ userInfo?.company }} <br> {{ userInfo?.department }}
        </VListItem>
        <VListItem
          style="color:#F4C020; font-size:13px;"
        >
          ------------------------------------------------
        </VListItem>

        <VListItem
          v-for="(item, index) in items"
          :key="index"
          :to="item.link"
        >
          <VListItemTitle>{{ item.title }}</VListItemTitle>
        </VListItem>
      </VList>
    </VMenu>

    <div class="language-picker">
      <CMLanguagePicker />
    </div>
  </VAppBar>
</template>

<script>
import CMLanguagePicker from '@/components/Common/CMLanguagePicker.vue'
import CMReleaseVersion from '@/components/Common/CMReleaseVersion.vue'
import { getInfo } from '@/services/user'
import { mdiChevronDown } from '@mdi/js'
export default {
  name: 'LYNavBar',

  components: {
    CMLanguagePicker,
    CMReleaseVersion
  },

  data: () => ({
    chevronDown: mdiChevronDown,
    tokenTrue: false,
    showMenu: false,
    showMenuNetwork: false,
  }),

  computed: {
    logged () {
      return this.$sessionStorage.logged !== 'false'
    },
    items () {
      return [
        {
          title: this.$t('nav_bar.change_password'),
          link: '/user-configuration'
        },
        { title: this.$t('nav_bar.logout'), link: '/' }
      ]
    },
    networksItems () {
      return [{ title: this.$t('nav_bar.load_data'), link: '/file-importer' }]
    },
    userInfo () {
      return JSON.parse(this.$sessionStorage.userInfo)
    }
  },

  watch: {
    async logged () {
      this.tokenTrue = sessionStorage.logged !== 'false'

      if (this.tokenTrue) {
        this.completeInfo()
      }
    },
    '$i18n.locale': function (val) {
      if (this.userInfo.is_superuser) { // Is admin
        this.items.splice(0, 0, {
          title: this.$t('nav_bar.admin_panel'),
          link: '/user-admin'
        })
      }
    }
  },
  mounted () {
    if (this.$route.name !== 'Login') {
      this.completeInfo()
    }
  },

  methods: {
    async completeInfo () {
      try {
        const result = await getInfo()
        this.$sessionStorage.userInfo = JSON.stringify(result)

        if (result.is_superuser || result.is_staff) {
          this.items.splice(0, 0, {
            title: this.$t('nav_bar.admin_panel'),
            link: '/user-admin'
          })
        }
      } catch (error) {
        console.log(error)
      }
    },

    setArea () {
      this.$store.dispatch('setElement', {
        path: 'showSetAreaForm',
        value: true
      })
    }
  }
}
</script>

<style>
.v-sheet.v-app-bar.v-toolbar{
  z-index: 503;
}
.v-toolbar__content{
  padding:4px 6px !important;
}
.v-toolbar__title{
  padding-left:10px !important;
}
.v-tooltip__content {
  opacity: 1 !important;
  background-color:rgb(54, 54, 54)
}
.yellow{
  color:#F4C020;
}
.network-button{
  color:#F4C020;
  font-size:15px;
  cursor:pointer;
  margin-right: 15px;
}

.map-area-button {
  margin-left: 35px;
}

.language-picker {
  margin-left: 10px;
}

.version-title {
  margin-right: 15px;
}
</style>
