export default {
  appname: 'App Name',
  lang: 'Language',
  ok: 'Ok',
  add: 'Add',
  cancel: 'Cancel',
  close: 'Close',
  remove: 'Remove',
  delete: 'Delete',
  delete_all: 'Delete all',
  open: 'Open',
  new: 'New',
  edit: 'Edit',
  reset: 'Reset',
  create: 'Create',
  confirm: 'Confirm',
  name: 'Name',
  measurements: 'Measurements',
  events: 'Events',
  select_all: 'Select All',
  select_by_id: 'Select by id',
  email: 'Email',
  grid_inspector_title: 'Grid Inspector',
  digital_twin: 'Digital Twin',
  grid_monitor_title: 'Grid Monitor',
  load_analysis_title: 'Load Analysis',
  tia_map_title: 'TIA Map',
  fetching: 'Fetching data',
  plots: 'Plots',
  plot_one: 'Plot',
  info: 'Info',
  phase: 'Phase',
  unit: 'Unit',
  power: 'Power',
  description: 'Description',
  optional: 'optional',
  no_data_available: 'No data available',
  consumption: 'Consumption',
  generation: 'Generation',
  start_date: 'Start date',
  end_date: 'End date',
  creation_date: 'Creation date',
  expiration_date: 'Expiration date',
  installation_date: 'Installation date',
  mode: 'Mode',
  and: 'and',
  type: 'Type',
  click_to_change: 'Click to change',
  voltage: 'Voltage',
  current: 'Current',
  apparent_power: 'Apparent Power',
  active_power: 'Active Power',
  reactive_power: 'Reactive Power',
  losses: 'Losses',
  heatmap: 'Heatmap',
  cable: 'Cable',
  length: 'Length',
  sync_map: 'Sync Map',
  formula: 'Formula',
  hours: 'Hours',
  not_available: 'Not available',
  no_info_available: 'No information available',
  seconds: 'seconds',
  second_abb: 's',
  file: 'File',
  file_title: 'File title',
  download: 'Download',
  select_data_download: 'Select data to download',
  download_csv: 'Download as csv',
  title_csv_to_download: 'Title of the csv file to create and download',

  validations: {
    item_required: 'Item is required',
    equal_or_greater: 'Item should be equal or greater than',
    item_between: 'Item should be between',
    integer: 'Item should be integer',
    number: 'Item should be a number',
    not_special_character: 'Special characters are not allowed',
    no_whitespace: 'Whitespaces not allowed'
  },

  login: {
    password: 'Password',
    login: 'Login',
    error_message: 'You have entered wrong email or password.'
  },

  grid_set: {
    title: 'Set grid to display ',
    by_area: 'By Zone',
    by_element: 'By Element',
    set_area: 'Set Grid',
    completed_fields: 'Completed fields',
    area_level: 'Level',
    error: 'An error occurred while accessing grid data. Please try again.',
    type_element: 'Type of Element',
    element_id: 'Element Id',
    element_name: 'Element Name',
    radius: 'Radius',
    getting_grid_msg: 'Getting grid data'
  },

  grid: {
    network: 'Network',
    networks: 'Networks',
    meter: 'Meter',
    meters: 'Meters',
    station: 'Station',
    stations: 'Stations',
    line: 'Line',
    lines: 'Lines',
    feeder: 'Feeder',
    feeders: 'Feeders',
    connection_point: 'Connection point',
    connection_points: 'Connection points',
    transformer: 'Transformer',
    transformers: 'Transformers',
    supervisors: 'Supervisors',
    advanced_supervisors: 'Advanced Supervisors',
    supply_points: 'Supply points',
    smart_meters: 'Smart meters',
    empty_grid: 'There is no available grid data to display.'
  },

  nav_bar: {
    title: 'Home',
    configure: 'Configure your network',
    change_password: 'Change Password',
    logout: 'Logout',
    load_data: 'Import Data',
    set_grid: 'Set Grid',
    admin_panel: 'Admin Panel'
  },

  admin: {
    confirm_title: 'Are you sure?',
    confirm_message: ' The User will be deleted. Are you sure?',
    user_deleted: 'User Deleted',
    reset_password: 'Reset Password',
    delete_user: 'Delete User',
    user_selected: 'User Selected:',
    indication: 'indicates required field',
    department: 'Department',
    username: 'Username',
    email_hint: 'Will be your email for access',
    last_name: 'Last name',
    last_name_hint: 'Your Last Name',
    first_name: 'First name',
    first_name_rule: 'FirstName is required',
    add_user: 'Add User',
    company: 'Company',
    last_login: 'Last Login',
    reset_password_message: 'Password reset. Sends it to the user',
    add_user_message:
      'User created with this password. Copy it and enter with your email and this password',
    rows_per_page: 'Rows per page'
  },

  grid_surveyor: {
    loading_grid_surveyor_msg: 'Loading Dashboard',
    lines: 'Lines',
    connection_points: 'Connections Points',
    fuses: 'Fuses',
    clients: 'Clients'
  },
  substations: {
    station_count: 'N. Stations',
    transformer_count: 'N. Transformers',
    station_count_per_power: 'N. Stations per Power (kVA)',
    station_count_per_feeder_count: 'N. Stations per N. Feeders',
    transformer_count_per_feeder_count: 'N. Transformers per N. Feeders',
    transformer_count_per_power: 'N. Transformers per Power (kVA)',
    installed_stations_per_year: 'Installed Stations per Year',
    connection_per_station: 'Connections per Station',
    clients_per_station: 'Clients per Station',
    km_per_station: 'Km line per Station'
  },
  lines: {
    km_of_line: 'Km of line',
    km_per_station: 'Km per Station',
    m_per_connection: 'M per Connection',
    m_per_client: 'M per client',
    peak_current: 'Peak Current',
    underground: 'Underground',
    overhead: 'Overhead'
  },
  connection: {
    connection_points: 'N. Connection Points',
    avg_connection_points_per_station: 'Avg. Connection Points per Station',
    avg_meters_per_connection_points: 'Avg. Meters per Connection Points'
  },
  fuses: {
    fuses_count: 'N. Fuses',
    feeders_count: 'N. Feeders',
    maneuvers_count: 'N. Maneuvers'
  },
  clients: {
    clients_count: 'N. Clients'
  },

  map_nav_bar: {
    hide_bar: 'Hide toolbar',
    show_bar: 'Show toolbar',
    network_mode: 'Network mode',
    element_mode: 'Element mode',
    change_to_network_mode: 'Change to Network Mode',
    change_to_element_mode: 'Change to Element Mode'
  },
  map: {
    sld: 'Single Line Diagram',
    map_view: 'Map View'
  },
  map_info: {
    area: 'Area',
    volt_level: 'Volt. Level',
    low: 'Low',
    coordinates: 'Coordinates',
    element_hovered: 'Element hovered'
  },

  grid_inspector: {
    loading_msg: 'Loading Grid Inspector',
    raw: 'Raw View',
    connectivity: 'Connectivity View',
    hosting_capacity: 'Hosting Capacity View',
    hcm_generation: 'Generation',
    hcm_consumption: 'Consumption'
  },
  grid_panel: {
    general_information: 'General information',
    meters_information: 'Meters information',
    transformer_information: 'Transformer information',
    code: 'Code',
    max_current: 'Max. current',
    num_stations: 'N.Stations',
    num_transformers: 'N.Transformers',
    num_connection_points: 'N.Connection Points',
    num_clients: 'N.Clients',
    total_length_conductor: 'Total length conductor',
    tap_max: 'Tap max',
    tap_min: 'Tap min',
    tap_positions: 'Tap positions',
    voltage_regulator: 'Voltage regulator',
    num_station_sup: 'N.Station supervisors',
    num_line_sup: 'N.Line supervisors',
    num_lines: 'N.Lines',
    num_active_lines: 'N.Active lines'
  },

  hcm: {
    loading_msg: 'Loading Hosting Capacity Map',
    consumption: 'Consumption',
    not_available: 'Not available',
    reserve_kw: 'Reserve (kW)',
    reserves: 'Reserves',
    consumption_hc: 'Consumption Hosting Capacity',
    generation_hc: 'Generation Hosting Capacity',
    consumption_reserves: 'Consumption Reserves',
    generation_reserves: 'Generation Reserves',
    full_reserve: 'Full reserve',
    hc_id: 'Hosting Capacity id',
    available_capacity: 'Available capacity value',
    reserve_period_month: 'Reserve period (months)',
    months: 'months',
    description_optional: 'Description (optional)',
    worst_case_txt: 'Show worst case',
    not_available_hcm: 'The hosting capacity map is only available for the low voltage',
    delete_confirm_reserve: 'Are you sure you want to delete this reserve?',
    delete_reserve_error: 'An error occurred while deleting the reserve. Please try again.',
    add_reserve_error: 'An error occurred while adding the reserve. Please try again.',
    warning: 'Hosting capacity is not available for grids with more than 5000 networks.',
    warning_link: 'Select here a smaller area.'
  },

  dt: {
    digital_twin_project: 'Digital Twin Project',
    my_projects: 'My Projects',
    new_connections: 'New Connections',
    planning: 'Planning',
    loading_projects: 'Loading Projects',
    simulation_date: 'Simulation Date',
    number_of_cases: 'Number of cases',
    penetration: 'penetration',
    created_on: 'Created on',
    basic_info: 'Basic Information',
    select_area: 'Select Area',
    power_constraints: 'Power Quality Constraints',
    max_voltage_limit: 'Max. Voltage Limit',
    min_voltage_limit: 'Min. Voltage Limit',
    max_voltage_unbalance: 'Max. Voltage Unbalance',
    max_transformer_loading: 'Max. Transformer Loading',
    max_current_on_lines: 'Max. Current on lines',
    or: 'or',
    pvs_settings: 'PVs Settings',
    percentage_of_customers_with_pv: 'Percentage of costumers with PV',
    initial_pv: 'Initial PV power penetration in the LV system',
    pv_power_steps: 'PV power steps increments for simulation',
    evs_settings: 'EVs Settings',
    ev_nominal_power: 'EV charger nominal power',
    ev_battery_capacity: 'EV mean battery energy capacity',
    ev_daily_distance: 'EV daily distance travel: Mean',
    ev_power_factor: 'EV charger power factor',
    hps_settings: 'HPs Settings',
    n_hps: 'Number of heat pumps',
    hp_nominal_power: 'Heat pump nominal power',
    hp_nominal_factor: 'Heat pump nominal factor',
    hp_cop: 'Heating Coefficient of Performance (COP)',
    generating_dt: 'Generating Digital Twin',
    opening_dt: 'Opening Digital Twin',
    delete_confirm_project: 'Are you sure you want to delete this project?',
    back_to_form: 'Back to form',
    loading_dt: 'Loading Digital Twin',
    project: 'Project',
    select_project: 'Select project',
    simulate: 'Simulate',
    step_time: 'Step Time',
    created_date: 'Created Date',
    base_power: 'Base Power',
    project_info: 'Digital Twin Project Information',
    remove_client: 'Remove client',
    client_removed: 'Client removed successfully',
    change_phase: 'Change phase',
    phase_connection: 'Phase connection',
    no_meters_in_connection_point:
      'There are no meters in this connection point',
    active: 'Active',
    reactive: 'Reactive',
    n_pv: 'Number of PV systems',
    power_factor: 'Power Factor',
    ev_cp: 'EV charger power (kW)',
    ev_ec: 'EV energy capacity (kWh)',
    ev_cpf: 'EV charger power factor',
    ev_t0: 'EV possible charging t0',
    ev_t1: 'EV possible charging t1',
    ev_mean: 'EV mean daily km traveled',
    daily_km: 'Daily km traveled STD (%)',
    peak_power: 'Peak power of each system (kW)',
    pnom_hp: 'Pnom. of each Heat Pump (kW)'
  },
  new_connections: {
    editing: 'Editing',
    new_case: 'New Case',
    case_name: 'Case name',
    reference_case: 'Select reference case',
    uniform_load_scale: 'Uniform load scale',
    add_element: 'Add element',
    add_pv: 'Add PV',
    add_hp: 'Add HP',
    add_ev: 'Add EV',
    add_ess: 'Add ESS',
    add_custom_profile: 'Add custom profile',
    edit_cable: 'Edit cable',
    add_conn_point: 'Add connection point',
    remove_client: 'Remove client',
    change_phase: 'Change phase',
    disable: 'Disable',
    pv_conn_params: 'PV connection parameters',
    n_pvs: 'Number of PVs',
    phase_connection: 'Phase connection',
    installed_peak_power: 'Installed peak power',
    max_q_injection: 'Maximum reactive power injection',
    ev_conn_params: 'EV connection parameters',
    n_evs: 'Number of EV chargers',
    std: 'STD',
    ev_charging_time: 'EV charging time range (hours)',
    hp_conn_params: 'Heat pump connection parameters',
    hp_power_factor: 'Heat Pump power factor',
    current_changes: 'Current changes',
    hide_details: 'Hide details',
    see_details: 'See details',
    edit_element: 'Edit element',
    quantity: 'Quantity',
    changes: 'Changes',
    simulating_case_msg: 'Creating and simulating case',
    line_full: 'Line is full of connection points',
    toggle_state: 'Toggle state',
    confirm_toggle_state__msg:
      'You are going to change the state of this element. Are you sure?',
    new_connection_point: 'New connection point',
    max_real_p: 'Maximum real power (kW)',
    num_custom_profiles: 'Number of custom profiles',
    time_sampling: 'Time sampling',
    ess_mode: 'Mode',
    ess_node: 'Node',
    ess_phase_conn: 'Phase Connection',
    ess_sigid: 'Element ID',
    ess_sigmode: 'Direction of power flow',
    ess_pmaxin: 'Maximum Active Power can consume',
    ess_pmaxout: 'Maximum Active Power can inject',
    ess_qmaxin: 'Maximum Reactive Power can absorb',
    ess_qmaxout: 'Maximum Reactive Power can inject',
    ess_emax: 'Maximum kilo-watt-hour',
    min_state_charge: 'Minimum state of charge in %',
    max_state_charge: 'Maximum state of charge in %',
    current_state_charge: 'Current State of charge in %',
    constant_active_power: 'Constant active power in kw (PQ Mode)',
    constant_reactive_power: 'Constant reactive power in kvar (Volt Control)',
    volt_control: 'Volt Control',
    voltage_regulation_set_point: 'Voltage regulation set point',
    lower_power_limit: 'Lower Power Limit in kW (peakshave)',
    upper_power_limit: 'Upper Power Limit in kW (peakshave)',
    band_power_limit: 'Band of Power Limit in kW to trigger',
    ess_connection_parameters: 'ESS Connection Parameters',
    mode_and_phase: 'Mode and Phase',
    monitored_element: 'Monitored Element',
    max_energy_capacity: 'Maximum Energy Capacity',
    state_charge: 'State Of Charge',
    constant_active_reactive_power: 'Constant Active and Reactive Power',
    voltage_var_parameters: 'Voltage VAR Parameters',
    voltage_regulation_point: 'Voltage regulation set point',
    trigger_limit: 'Trigger Limit',
    input_less_or_equal_to_high: 'Low input must be less than or equal to high input',
    input_greater_or_equal_to_low: 'High input must be greater than or equal to low input',
    peak_shave_description: 'Peak Shave: Control the power flowing downstream in the line installed upstream of the selected connection point',
    cable: 'Cable',
    change_cable_current: 'Currently installed:',
    change_cable_r_km: 'R phase per km',
    change_cable_x_km: 'X phase per km',
    change_cable_r_neutral: 'R neutral per km',
    change_cable_x_neutral: 'X neutral per km',
    change_cable_maximum_current: 'Maximum current (A)',
    change_cable_maximum_neutral: 'Maximum neutral (A)'
  },
  cable_change_description: {
    id: 'ID',
    reference: 'Name',
    section_mm2: 'Section (mm2)',
    ampacity_amp: 'Ampacity (A)',
    voltage_kv: 'Voltage (kV)',
    conductor: 'Conductor',
    capacity_kva: 'Capacity (KVA)',
    isolation: 'Isolation',
    layout: 'Layout',
    i_max_amp: 'Maximum current (A)',
    i_max_neutral_amp: 'Maximum neutral (A)',
    r_cable_ohm_km: 'R phase per km',
    x_cable_ohm_km: 'X phase per km',
    b_cable_mho_km: 'B phase per km',
    r_neutral_ohm_km: 'R neutral per km',
    x_neutral_ohm_km: 'X neutral per km',
    b_neutral_mho_km: 'B neutral per km'
  },
  planning: {
    project_name: 'Project Name',
    violation: 'Violation',
    violation_percentage: 'Violation Percentage',
    penetration_level: 'Penetration level',
    level_of_penetration: 'Level of penetration',
    installed_capacity: 'Installed capacity',
    total_capacity: 'Total capacity installed',
    phA_undervoltage: 'phA Under Voltage',
    phB_undervoltage: 'phB Under Voltage',
    phC_undervoltage: 'phC Under Voltage',
    phA_overvoltage: 'phA Over Voltage',
    phB_overvoltage: 'phB Over Voltage',
    phC_overvoltage: 'phC Over Voltage',
    voltage_negative_sequence: 'Voltage Negative Sequence',
    voltage_zero_sequence: 'Voltage Zero Sequence',
    phA_overcurrent: 'phA Over Current',
    phB_overcurrent: 'phB Over Current',
    phC_overcurrent: 'phC Over Current',
    phN_overcurrent: 'phN Over Current',
    overloading_transformer: 'Overloading Transformer',
    pv_info_montecarlo_simulation_quantity: 'There will be run 10 montecarlo simulations, each one formed with 500 power flow simulations each of 24 hours of date provided above.',
    pv_info_simulation_increased: 'In each simulation it will be increased the total peak power installed (kWp) in the network with start power and step power provided below. ',
    pv_info_power_equally_shared: 'The power will be equally shared among the clients randomly allocated, but always fulfilling the penetration level defined below, and maintaining their phase connectivity.',
    pv_info_results_may_change: 'The results may change depending on selected day of simulation due to the impact of irradiance profiles.',
    ev_info_montecarlo_simulation_quantity: 'There will be run 10 montecarlo simulations, each one formed with 500 power flow simulations each of 24 hours of date provided above.',
    ev_info_simulation_increased: 'In each simulation it will be increased the penetration of EVs in the networks (%). It will start at 10% penetration and finish at 100%.',
    ev_info_same_charger: 'The same EV charger with charging pattern fulfilling conditions specified below will be installed in random clients fulfilling specified penetration in each simulation.'
  },
  kpi: {
    apparent_power_avg: 'Apparent power: Avg',
    apparent_power_phase_share: 'Apparent Power: Phase Share (Avg)',
    capacity_factor: 'Capacity Factor',
    current_avg: 'Current: Avg',
    current_max: 'Current: Max',
    current_min: 'Current: Min',
    current_unbalance_avg: 'Current: Unbalance (Avg)',
    current_unbalance_rate: 'Current: Unbalance (Rate)',
    energy_losses: 'Energy losses',
    over_utilization_rate: 'Over-utilization rate',
    overload_rate: 'Overload rate',
    ev: 'Electric vehicles',
    pv_systems: 'PV systems',
    hps: 'Heat Pumps',
    load_factor: 'Load Factor',
    power_factor_avg: 'Power Factor (Avg)',
    voltage_unbalance_avg: 'Voltage Unbalance (Avg)',
    voltage_unbalance_rate: 'Voltage Unbalance (Rate)',
    voltage_qualified_rate: 'Voltage: Qualified Voltage (Rate)',
    voltage_overvoltage: 'Voltage: Overvoltage (Rate)',
    voltage_undervoltage: 'Voltage: Undervoltage (Rate)',
    voltage_min: 'Voltage: Min',
    voltage_max: 'Voltage: Max',
    voltage_avg: 'Voltage: Avg',
    ldc: 'Load Duration Curve',
    ldc_current: 'Load Duration Curve: Current',
    ldc_power: 'Load Duration Curve: Power',
    unbalanced_factor: 'Unbalanced Factor',
    unbalance_factor_current: 'Unbalance Factor Current',
    unbalance_factor_Voltage: 'Unbalance Factor Voltage',
    vl: 'Voltage Length',
    energy_supplied_station: 'Energy supplied (by Station)',
    energy_supplied_pv: 'Energy supplied (by PVs)',
    duration_p_out_max: 'P Out from subscription point: Incurrences',
    duration_p_in_max: 'P In from subscription point: Incurrences',
    duration_q_out_max: 'Q Out from subscription point: Incurrences',
    duration_q_in_max: 'Q In from subscription point: Incurrences',
    power_p_out_max: 'P Out from subscription point: Power',
    power_p_in_max: 'P In from subscription point: Power',
    power_q_out_max: 'Q Out from subscription point: Power',
    power_q_in_max: 'Q In from subscription point: Power'
  },
  plot: {
    phase_a: 'PhaseA',
    phase_b: 'PhaseB',
    phase_c: 'PhaseC',
    phase_n: 'PhaseN',
    power_kw: 'Power(kW)',
    voltage_v: 'Voltage(V)',
    current_a: 'Current(A)',
    time_h: 'Time(h)',
    subscription_limit: 'Subscription limits',
    consumption_limit: 'Consumption for upstream grid limit',
    injection_limit: 'Injection to upstream grid limit',
    download: 'Download',
    export_data: 'Export data plot',
    select_phase_export: 'Select phases to export'
  },

  importer: {
    title: 'File importer',
    reference_column: 'Reference Column',
    time_column: 'Time Column',
    signal: 'Signal',
    measurement_column: 'Measurement Column',
    load_file: 'Load File',
    measurement_file_sent: 'Measurement file sent',
    topology: 'Topology',
    custom_profile: 'Custom profile',
    parsing_file: 'Parsing file',
    uploading_file: 'Uploading file',
    only_csv_files_allowed: 'Only .csv files allowed',
    at_least_two_columns: 'At least two columns required (active and reactive)',
    unit_expected: 'unit expected',
    expected_values_between: 'Expected values between',
    negative_values_grid_injection: 'Negative values correspond to generation/grid injection',
    positive_values_consumption: 'Positive values correspond to consumption',
    information_file: 'Information in file',
    has_been_successfully_uploaded: 'has been successfully uploaded',
    failed_uploading_file: 'Failed uploading file',
    error_between_lines: 'Error between lines'

  },

  grid_monitor: {
    select_all_stations: 'Select all stations',
    empty_tree:
      'No data available. Select a valid option to see the information here',
    search: 'Search',
    assets: 'Assets',
    forecast: 'Forecast',
    date_start: 'Date start',
    date_end: 'Date end',
    aggregation: 'Aggregation',
    aggregation_name: 'Aggregation name',
    enter_name: 'Enter a name',
    operator: 'Operator',
    send_to_plot: 'Send to Plot',
    sending: 'Sending...',
    total: 'Total',
    variables: 'Variables',
    expression_calculations: 'Expression calculations',
    operation_between_factor: 'Operation between factor',
    operation: 'Operation',
    addition: 'Addition',
    subtraction: 'Subtraction',
    multiplication: 'Multiplication',
    division: 'Division',
    factor: 'Factor',
    actions: 'Actions',
    draw: 'Draw',
    expression: 'Expression',
    depth: 'Depth',
    sampling_time: 'Sampling time'
  },
  top_down: {
    station_name: 'Station',
    percent_missing_data: 'Missing data (%)',
    kva_rated_power: 'Rated power (kVa)',
    kva_peak_power: 'Peak power (kVa)',
    percent_peak_utilization: 'Peak utilization (%)',
    kva_mean_power: 'Mean power (kVa)',
    percent_utilization_factor: 'Utilization factor (%)',
    percent_time_above_100_percent: 'Time (%) load>100%',
    percent_time_above_120_percent: 'Time (%) load>120%',
    percent_time_above_75_percent: 'Time (%) load>75%',
    percent_time_above_50_percent: 'Time (%) load>50%',
    kwh_total_energy_consumed: 'Total energy consumed (kWh)',
    kwh_energy_consumed_above_100_percent: 'Energy consumed load>100%(kWh)',
    kwh_energy_consumed_above_120_percent: 'Energy consumed load>120%(kWh)',
    count_overload_events: 'Count overload events',
    count_critical_load_events: 'Count maximum load events',
    hours_duration_above_rated_power: 'Hours above rated power',
    hours_duration_above_maximum_power: 'Hours above maximum power',
    load_analysis: 'Load Analysis',
    last_week: 'Last week',
    last_month: 'Last month',
    last_year: 'Last year',
    ytd: 'YTD'
  }
}
