<template>
  <v-app id="plot-generic">
    <div class="plot-container">
      <div :id="name" class="plotly-container">
        <Plotly
        :key="cphase"
        :name="name"
        :data="getDataPlot"
        :layout="getLayoutPlot"
        :displaylogo="false"
        @legendclick="clickLegend($event)"
      />
      </div>

      <div class="button-download">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <button v-bind="attrs" v-on="on" @click="openDownload"><v-icon>{{ downloadIcon }}</v-icon></button>
          </template>
        <span>Download</span>
        </v-tooltip>
      </div>

    </div>
    <v-dialog
      v-model="downloadDialog"
      max-width="400"
      overlay-opacity="0.5"
    >
      <DTExportPlotData
        :horizontal-plane="horizontalPlane"
        :consolidated-phase="consolidatedPhase"
        :plot-type="name"
        @closeDownload="closeDownload"
      />
    </v-dialog>
  </v-app>
</template>

<script>

import { Plotly } from 'vue-plotly'
import { mapState } from 'vuex'
import { mdiDownload } from '@mdi/js'
import DTExportPlotData from '@/components/DigitalTwin/Forms/DTExportPlotDataForm.vue'

export default {
  name: 'DtPanelPlotGeneric',
  components: {
    Plotly,
    DTExportPlotData
  },
  props: {
    name: { type: String, default: null },
    dataPlot: { type: Array, default: null },
    typeValue: { type: Number, default: null }, // 1 Current (A), 2 Power (KW)
    samplingPeriod: { type: Number, default: null },
    yAxis: { type: String, default: null },
    xAxis: { type: String, default: null },
    pavY: { type: Float32Array, default: null },
    pbvY: { type: Float32Array, default: null },
    pcvY: { type: Float32Array, default: null },
    pnvY: { type: Float32Array, default: null },
    curve: { type: Boolean, default: false }
  },
  data: () => ({
    downloadIcon: mdiDownload,
    downloadDialog: false,
    cphase: 0,
    horizontalPlane: null,
    consolidatedPhase: []
  }),
  computed: {
    ...mapState({
      vphaseA: (state) => state.Plots.phaseA,
      vphaseB: (state) => state.Plots.phaseB,
      vphaseC: (state) => state.Plots.phaseC,
      vphaseN: (state) => state.Plots.phaseN
    }),
    getDataPlot () {
      // Variables for Plot
      const arrayX = []
      const valuesPlot = this.dataPlot

      if (!this.curve) {
        const date = new Date(this.$store.state.DTProject.sim.InitDate)
        const gap = date.getTimezoneOffset()

        let initDate = new Date(this.$store.state.DTProject.sim.InitDate).getTime()
        initDate += gap * 60 * 1000
        const endDate = new Date(this.$store.state.DTProject.sim.EndDate).getTime()

        let steps = Math.round((endDate - initDate) / (1000 * 60 * 60)) * this.$TIME_INTERVAL
        steps += 24 * this.$TIME_INTERVAL
        const options = {
          year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'
        }
        for (let i = 0; i < steps; i += 1) {
          const d = new Date()
          d.setTime(initDate + (i * parseFloat(this.$TIME_SPLIT)))
          arrayX.push(d.toLocaleDateString('en-UK', options))
        }
      } else {
        valuesPlot[0][1].sort((a, b) => b - a)
        valuesPlot[1][1].sort((a, b) => b - a)
        valuesPlot[2][1].sort((a, b) => b - a)
        valuesPlot[3][1].sort((a, b) => b - a)
        for (let i = 0; i < valuesPlot[0][1].length; i += 1) {
          arrayX.push(i * this.samplingPeriod)
        }
      }

      this.horizontalPlane = arrayX

      const PhaseA = {
        x: arrayX,
        y: this.pavY,
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseA',
        line: {
          shape: 'hvh'
        },
        visible: this.vphaseA
      }
      const PhaseB = {
        x: arrayX,
        y: this.pbvY,
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseB',
        line: {
          shape: 'hvh'
        },
        visible: this.vphaseB
      }
      const PhaseC = {
        x: arrayX,
        y: this.pcvY,
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseC',
        line: {
          shape: 'hvh'
        },
        visible: this.vphaseC
      }
      const PhaseN = {
        x: arrayX,
        y: this.pnvY,
        type: 'scatter',
        mode: 'lines',
        name: 'PhaseN',
        line: {
          shape: 'hvh'
        },
        visible: this.vphaseN
      }

      let datos = []
      if (this.yAxis === 'UF') {
        // For the plots that came from DtPanelPlotPowerUF
        datos = [{
          x: arrayX,
          y: valuesPlot,
          type: 'scatter',
          mode: 'lines',
          line: {
            shape: 'hvh'
          }
        }]
      } else {
        datos = [PhaseA, PhaseB, PhaseC, PhaseN]
      }

      this.consolidatedPhase = [];

      datos.forEach(phase => {
        let consolidated = [];
        consolidated.push({phase:`${phase.name}`, yaxis:`${phase.y}`})
        this.consolidatedPhase.push(consolidated)
      })

      return datos
    },

    getLayoutPlot () {
      const layout = {
        title: this.name,
        legend: {
          orientation: 'h',
          valign: 'top',
          yanchor: 'top',
          bgcolor: 'rgba(0,0,0,0)',
          font: {
            size: '8',
            family: 'Faktum Light'
          }
        },
        height: 450,
        margin: {
          l: 60,
          r: 20,
          b: 100,
          t: 80,
          pad: 5
        },
        xaxis: {
          title: this.xAxis,
          linecolor: '#fff',
          nticks: 5,
          gridcolor: '#2c2c2c',
          showspikes: true,
          spikethickness: 2,
          spikedash: 'dot',
          spikecolor: '#fff',
          spikemode: 'across',
          tickangle: 0,
          tickfont: {
            size: 10
          }
        },
        yaxis: {
          title: this.yAxis,
          linecolor: '#fff',
          gridcolor: '#2c2c2c'
        },
        paper_bgcolor: '#000',
        plot_bgcolor: '#191919',
        font: {
          color: '#fff',
          family: 'Faktum Light'
        },
        hovermode: 'x',
        modebar: {
          activecolor: '#f4c020',
          color: '#fff',
          remove: ['zoomin2d', 'zoomout2d', 'resetscale2d']
        }
      }

      return layout
    }
  },
  methods: {
    openDownload() {
      this.downloadDialog = true
    },
    closeDownload() {
      this.downloadDialog = false
    },
    clickLegend (evt) {
      const phases = ['phaseA', 'phaseB', 'phaseC', 'phaseN']
      const phaseIndex = evt.expandedIndex

      if (this.$store.state.Plots[phases[phaseIndex]] === 'true') {
        this.$store.dispatch('setElement', { path: `Plots.${phases[phaseIndex]}`, value: 'legendonly', root: 'Plots' })
      } else {
        this.$store.dispatch('setElement', { path: `Plots.${phases[phaseIndex]}`, value: 'true', root: 'Plots' })
      }

      this.cphase = phaseIndex
    }
  }
}
</script>
<style>
.plot-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.plotly-container {
  flex: 1;
}

.button-container {
  position: absolute;
  top: 30px;
  right: 10px;
}

.button-download {
  position: absolute;
  top: 30px;
  right: 50px;
}
</style>
